import { useLayoutEffect } from 'react';
import Navbar from '../components/Navbar';
import Project from '../components/Project';
import { useMediaQuery } from 'react-responsive'

const Projects = () => {

  useLayoutEffect(() => {
    document.body.style.backgroundColor = "#f7f6f0"
  });

  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1284px)' })

  return (
    <div>
      <Navbar />
      <div style={{ marginLeft: isTabletOrMobile ? "30px" : "100px" }}>
        <h1>Projects</h1>
        <br />
        <Project title="Axo" link={""} stack={["TypeScript", "Java", "Go"]} desc="Software engineer at a startup's e-commerce site built to manage crop production for organic farmers and handle payment and backend processes." />
        <Project title="Perton" link="" stack={["C", "C++"]} desc="A programming language with Ruby-like syntax and compiler frontend built upon LLVM. Equipped with a standard library that supports data structures, multithreading and concurrency, and networking." />
        <Project title="SmartBoard" link="" stack={["TypeScript", "React", "Firebase", "Redis"]} desc="A real-time chess app where users can play against similarly rated players in blitz and classic modes.
Utilizes Stockfish API to dynamically determine a player's strength and adjust CPU difficulty accordingly." />
        <Project title="Algorithmic Trading Engine" link="" stack={["Python", "Go", "C++"]} desc="A monorepo of trading algorithms, backtesting libraries, model research and validation, and trading infrastructure. Strategies are rigorously backtested using internal tooling and deployed to production, executing trades using the Alpaca Markets API." />
        <Project title="AWS Deployment and Migration Workflows" link="" stack={["Java", "Go", "AWS", "Docker", "Kubernetes"]} desc="Leveraged Argo Workflows and Amazon Web Services to automate the deployment of multiple company
projects and monitor database migrations." />
      </div>
    </div>
  );
};

export default Projects;