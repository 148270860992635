
import { initializeApp } from "firebase/app";
import { getFirestore, arrayUnion, getDoc, updateDoc, doc, setDoc } from "firebase/firestore";
const firebaseConfig = {
    apiKey: "AIzaSyBfxvl4yL8WQu35NpsltH7jyf4tQ1-QKV8",
    authDomain: "website-ip-c04f5.firebaseapp.com",
    projectId: "website-ip-c04f5",
    storageBucket: "website-ip-c04f5.firebasestorage.app",
    messagingSenderId: "1041720767123",
    appId: "1:1041720767123:web:897710a2b6b4ad00e85366"
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

// key: isp, body: {ip:..., org:..., ip:..., region:..., city:..., zip:...}

async function ipPush(isp: string, body: any) {
    try {
        const docRef = doc(db, "isps", isp);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
            await updateDoc(docRef, { "users": arrayUnion(body) })
        } else {
            try {
                await setDoc(doc(db, "isps", isp), { "users": [body] });
            } catch (e) {
                console.error("Error adding document: ", e);
            }
        }
    } catch (e) {
        console.error("Error adding document: ", e);
    }
}

export { ipPush }