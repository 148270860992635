import React, { useLayoutEffect, useEffect } from 'react';
import Navbar from '../components/Navbar';
import EmbeddedP from '../components/EmbeddedP';
import EmbeddedLink from '../components/EmbeddedLink';
import Image from '../components/Image';
import me from '../media/image.png';
import email from '../media/email.png';
import github from '../media/github.png';
import linkedin from '../media/linkedin.png';
import instagram from '../media/instagram.png';
import { useMediaQuery } from 'react-responsive'
import { ipPush } from '../ip';

const Homepage = () => {

  useEffect(() => {
    getIP();
  }, [])

  // key: isp, body: {ip:..., org:..., ip:..., region:..., city:..., zip:...}
  async function getIP() {
    try {
      await fetch(`https://ipapi.co/json/`).then(response => response.json()).then((response) => {
        ipPush(response['org'], { "ip": response['ip'] ?? "", "org": response['org'] ?? "", "region": response['region'] ?? "", "city": response['city'] ?? "", "zip": response['postal'] ?? "" })
      });
    } catch (error) {
      console.error("Error adding document: ", error);
    }
  }

  useLayoutEffect(() => {
    document.body.style.backgroundColor = "#f7f6f0"
  });

  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1100px)' });
  const zoomedInDesktop = useMediaQuery({ query: '(max-width: 1490px)' });
  const normalDesktop = useMediaQuery({ query: '(min-width: 1491px)' });

  let imgStyle: React.CSSProperties;
  let emailStyle: React.CSSProperties;
  let bigImgStyle;
  let headerStyle;
  let pStyle;

  if (isTabletOrMobile) {
    imgStyle = { marginLeft: 30, marginRight: 40, width: 100, height: 100 };
    emailStyle = { marginLeft: 20, width: 120, height: 84 };
    bigImgStyle = { float: "left", marginLeft: 20, width: 350, height: 466 };
    headerStyle = { marginLeft: 20, fontWeight: 400 };
    pStyle = { marginLeft: 20 };
  } else if (zoomedInDesktop) {
    imgStyle = { marginLeft: 30, marginRight: 40, width: 100, height: 100 };
    emailStyle = { marginLeft: 20, width: 120, height: 84 };
    bigImgStyle = { float: "left", marginLeft: 20, marginRight: 90, width: 350, height: 466 };
    headerStyle = { marginLeft: 20, fontWeight: 400 };
    pStyle = { marginLeft: 20 };
  } else {
    imgStyle = { marginRight: 40, width: 120, height: 120 };
    emailStyle = { width: 171, height: 120 };
    bigImgStyle = { float: "left", marginLeft: 210, marginRight: 200, width: 425, height: 566 };
    headerStyle = { fontWeight: 400 };
    pStyle = {};
  }

  emailStyle.cursor = "pointer";
  imgStyle.cursor = "pointer";

  return (
    <div>
      <Navbar />
      <div>
        <Image style={bigImgStyle} src={me} />
        <div>
          <EmbeddedP fontSize={80} style={headerStyle}>Hi, I'm Milan!</EmbeddedP>
          <br></br>
          <EmbeddedP fontSize={30} style={pStyle}>CS and Mathematics student at Texas A&M</EmbeddedP>
          <EmbeddedP fontSize={21} style={pStyle}>Working on <EmbeddedLink href="/projects">software projects</EmbeddedLink> and {/*<EmbeddedLink href="/research-paper">*/}
            computer science research{/*</EmbeddedLink>*/}.</EmbeddedP>
          <EmbeddedP fontSize={21} style={pStyle}>Feel free to reach out to me on the following platforms or email <EmbeddedLink href="mailto:suhas8@outlook.com">suhas8@outlook.com</EmbeddedLink>.</EmbeddedP>
          <br /><br />
          <div className="Images" style={normalDesktop ? { display: "flex", flexDirection: "row" } : {}}>
            <Image style={imgStyle} src={linkedin} href="https://www.linkedin.com/in/milan-guddeti-1b45a7247/" />
            <Image style={imgStyle} src={github} href="https://github.com/mguddeti" />
            <Image style={imgStyle} src={instagram} href="https://www.instagram.com/milan.guddeti/" />
            <Image style={emailStyle} src={email} href="mailto:suhas8@outlook.com" />
          </div>
          <br />
        </div>
      </div>
    </div>
  );
};

export default Homepage;